.content {
    padding-top: 7rem !important;
    padding-bottom: 1.5rem !important;
}


.react-datepicker-wrapper {
    width: 100%;
  }
  
  .react-datepicker {
    font-family: Arial, sans-serif;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
  }
  
  .react-datepicker__header {
    background-color: #fff;
    border-bottom: none;
  }
  
  .react-datepicker__current-month {
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .react-datepicker__day-name, .react-datepicker__day {
    width: 2rem;
    line-height: 2rem;
    margin: 0.1rem;
  }
  
  .react-datepicker__day--selected {
    background-color: #007bff;
    color: white;
  }
  
  .react-datepicker__navigation {
    top: 15px;
  }
  
  .react-datepicker__navigation--previous {
    left: 15px;
  }
  
  .react-datepicker__navigation--next {
    right: 15px;
  }
  
  .custom-datepicker {
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  
  .custom-datepicker:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }

  .loading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
}

.loading-content {
    text-align: center;
}

.blur-form {
    filter: blur(5px);
}